import React from 'react';

import './App.scss';
import Gallery from './components/Gallery';

function App() {
  return (
    <div className="wrapper">
      <h1 className="App-header">Geniem React code-test</h1>
      <Gallery />
    </div>
    
  );
}

export default App;
